import React from 'react';
import { MEMBERSHIP_TEST_PLAN } from 'utils/constants/dataTest';

import * as S from '../styles';
import { TestPlanStatus } from '../types';

type MembershipTestPlanDesktopNavProps = {
  goToSlide: (target: number) => void;
  activeMenu: number;
  testPlanStatus: TestPlanStatus[];
};

const MembershipTestPlanDesktopNav = ({
  goToSlide,
  activeMenu,
  testPlanStatus,
}: MembershipTestPlanDesktopNavProps) => (
  <S.StatusContainer data-testid={MEMBERSHIP_TEST_PLAN.DESKTOP_NAV}>
    {testPlanStatus?.map((status, idx) => (
      <S.Status
        key={status.id}
        active={idx === activeMenu}
        onClick={() => goToSlide(idx)}
      >
        {status.title} ({status?.items?.length})
      </S.Status>
    ))}
  </S.StatusContainer>
);

export default MembershipTestPlanDesktopNav;
