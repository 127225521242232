import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { RESULTS_ROOT } from 'utils/constants/urls';
import { findAllByKey, getSkuKey, logError, numericDate } from 'utils/helpers';
import { TestHistory } from 'utils/hooks/useAccountSettings/types';
import { CreditRedemptionModalContext } from 'utils/hooks/useCreditRedemptionModal/context';
import { categoryProductSKUMap } from 'utils/hooks/useProductsByCategory/membershipProductSkuMap';
import useProductsBySKU from 'utils/hooks/useProductsBySKU';
import { TestPlanContext } from 'utils/hooks/useTestPlan/context';
import { TestPlanItem, TestPlanState } from 'utils/hooks/useTestPlan/types';
import { UserContext } from 'utils/hooks/useUserContext/context';

import * as S from './styles';

export const TEST_PLAN_CARD_ID = 'test-plan-card-id';

export type Props = TestPlanItem & TestHistory;

const TestPlanCard = (props: Props) => {
  const { id, attributes, type } = props;
  const { openCreditRedemptionModal } = useContext(
    CreditRedemptionModalContext,
  );
  const {
    userData: { isMember, loggedIn, activeCredits },
  } = useContext(UserContext);

  const { removeTestPlanItem } = useContext(TestPlanContext);

  const claimTest = async () => {
    if (!attributes.spree_variant_id) return;
    const testVariantId = attributes.spree_variant_id;
    openCreditRedemptionModal(
      Number(testVariantId),
      { id, attributes, type }
    );

    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.TEST_PLAN.redeem.testPlanItem,
        description: ANALYTICS.LABELS.TEST_PLAN.claim,
        variantName: attributes?.sku || '',
        isMember,
        loggedIn,
      },
    });
  };

  const removeTest = async () => {
    await removeTestPlanItem(id);
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.TEST_PLAN.upcoming,
        description: ANALYTICS.LABELS.TEST_PLAN.remove,
        variantName: attributes?.sku || '',
        isMember,
        loggedIn,
      },
    });
  };

  const viewResults = async () => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.TEST_PLAN.claimed,
        description: ANALYTICS.LABELS.TEST_PLAN.viewResults,
        variantName: attributes?.sku || '',
        isMember,
        loggedIn,
      },
    });
  };

  const getActionButtons = () => {
    if (props['kit-result']) {
      return (
        <S.TestButton
          appearance="secondary"
          onClick={viewResults}
          href={`${RESULTS_ROOT}/results/${props['kit-result']?.number}`}
        >
          View Results
        </S.TestButton>
      );
    } else if (attributes?.state === TestPlanState.upcoming) {
      return (
        <>
          {attributes.spree_variant_id && (
            <S.TestButton
              appearance="primary"
              onClick={claimTest}
              isDisabled={activeCredits === 0}
            >
              Redeem test
            </S.TestButton>
          )}

          <S.RemoveButton appearance="secondary" onClick={removeTest}>
            Remove
          </S.RemoveButton>
        </>
      );
    } else {
      return null;
    }
  };

  const getTestDate = (): string => {
    try {
      if (props['order-date']) return numericDate(props['order-date']);
      if (!attributes.sku) return '';
      return attributes?.actionable_date || '';
    } catch (err) {
      logError((err as Error).message, {
        component: 'TestPlanCard',
        method: 'getTestDate',
      });
      return '';
    }
  };

  const getTestData = () => {
    const completedSKU = props['product-sku'] || '';
    const productSku =
      findAllByKey(categoryProductSKUMap, getSkuKey(attributes, true) || '') ??
      [];
    const membershipSkuSlug = productSku[0]?.pdpSlug ?? '';
    const sku = completedSKU || membershipSkuSlug;
    if (!sku) return null;
    return useProductsBySKU(sku);
  };

  const testData = getTestData();

  const testTitle = testData?.contentfulProductInfo.displayName;
  const testImageSource = testData?.contentfulProductInfo?.boxImage;
  const testImage =
    testImageSource && (getImage(testImageSource) as IGatsbyImageData);
  const testDate = getTestDate();
  const buttons = getActionButtons();

  return (
    <S.TestCard data-testid={TEST_PLAN_CARD_ID}>
      <S.TestCardLeftContent>
        {testImage && (
          <S.Circle>
            <S.ImgContainer>
              <GatsbyImage image={testImage} alt={testTitle || ''} />
            </S.ImgContainer>
          </S.Circle>
        )}
        <S.TestCardContent>
          {testTitle && <S.TestTitle>{testTitle}</S.TestTitle>}

          <S.Description>
            {props['order-date']
              ? `Date Claimed: ${testDate}`
              : testData?.contentfulProductInfo.shortDescription}
          </S.Description>
        </S.TestCardContent>
      </S.TestCardLeftContent>
      <S.TestActions>{buttons}</S.TestActions>
    </S.TestCard>
  );
};

export default React.memo(TestPlanCard);
