import React, { useContext } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { scrollToId } from 'utils/helpers';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { MembershipTypeOptions } from 'utils/types';
import emptyImg from './images/howItWorks.svg';
import * as S from './styles';

type TestPlanEmpty = {
  isClaimTabActive: boolean;
};

export const incomingTitle =
  "It looks like you haven't added any tests to your queue";
export const incomingSubTitle =
  'Select tests from the choices above to add to your queue';
export const claimTitle = "It looks like you haven't claimed any tests yet.";
export const claimSubTitle =
  'Use a credit to redeem a test from your upcoming queue';

export const TestPlanEmpty = ({
  isClaimTabActive = false,
}: TestPlanEmpty): JSX.Element => {
  const title = !isClaimTabActive ? incomingTitle : claimTitle;
  const subTitle = !isClaimTabActive ? incomingSubTitle : claimSubTitle;
  const {
    userData: { loggedIn, isMember, membershipType },
  } = useContext(UserContext);

  const handleClick = async () => {
    const hash = `${
      membershipType == MembershipTypeOptions.current ? 'current' : 'control'
    }-membership-tests-included-authorized`;
    scrollToId(hash);

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.TEST_PLAN.add.emptySelection,
        isMember: Boolean(isMember),
        loggedIn: isMember ? true : loggedIn,
      },
    });
  };

  return (
    <S.TestPlanEmptyWrapper>
      <S.ImageWrapper>
        <S.Img src={emptyImg} alt="Everlywell" />
      </S.ImageWrapper>
      <S.AddTestsTitleWrapper>{title}</S.AddTestsTitleWrapper>
      <S.AddTestsSubtitleWrapper>{subTitle}</S.AddTestsSubtitleWrapper>
      <S.AddTestsWrapper>
        <S.AddTests appearance="secondary" onClick={handleClick}>
          Add to queue
        </S.AddTests>
      </S.AddTestsWrapper>
    </S.TestPlanEmptyWrapper>
  );
};
