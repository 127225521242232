import { graphql, useStaticQuery } from 'gatsby';
import { logError } from 'utils/helpers';
import { ProductBySKUType } from 'utils/types';

const useProductsBySKU = (sku: string): ProductBySKUType | null => {
  if (!sku) return null;

  try {
    const { allSpreeProduct } = useStaticQuery(graphql`
      query {
        allSpreeProduct {
          nodes {
            slug
            productId
            contentfulProductInfo {
              productCardImage {
                description
                gatsbyImageData(placeholder: BLURRED, quality: 75)
              }
              boxImage {
                gatsbyImageData(placeholder: BLURRED)
                description
              }
              displayName
              slug
              collectionMethods {
                ... on ContentfulCollectionMethod {
                  id
                  name
                }
              }
              symptomList {
                symptoms {
                  title
                }
              }
              shortDescription
              medicalDisclaimer {
                medicalDisclaimer
              }
              category {
                title
                id
              }
            }
            master_variant_id
            variants {
              price
              is_master
              id
            }
            sku
          }
        }
      }
    `);

    const product =
      allSpreeProduct?.nodes &&
      allSpreeProduct?.nodes.filter(
        (node: ProductBySKUType) => sku == node.sku,
      );

    return product ? product[0] : null;
  } catch (err) {
    logError(`There was an issue fetching and filtering useProductsBySKU`, {
      function: 'useProductsBySKU',
      stackTrace: err,
    });
    return null;
  }
};
export default useProductsBySKU;
