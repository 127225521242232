import MembershipTestPlanSection from 'components/MembershipPDP/MembershipTestPlanSection';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageSectionType } from 'utils/types';

export type TestsPlanContainerProps = {
  content: PageSectionType;
};

export const TestPlanContainer: React.FC<TestsPlanContainerProps> = ({
  content,
}: TestsPlanContainerProps) => {
  const { title = '', headline = '', subHeadline = '' } = content;

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.TEST_PLAN.label}
      slug={content.slug}
    >
      <MembershipTestPlanSection
        uid={content.slug || ''}
        title={title}
        headline={headline}
        subHeadline={subHeadline}
      />
    </TrackVisibilityWrapper>
  );
};

export default TestPlanContainer;
