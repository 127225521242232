import TestPlanCard from 'components/TestPlan/TestPlanCard';
import { TestPlanEmpty as MembershipTestPlanEmpty } from 'components/TestPlan/TestPlanEmpty';
import React from 'react';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import Swiper from 'swiper/types/swiper-class';
import { MEMBERSHIP_TEST_PLAN } from 'utils/constants/dataTest';

import * as S from '../styles';
import { TestPlanStatus } from '../types';

type MembershipTestPlanQueueProps = {
  setSwiperContent: React.Dispatch<React.SetStateAction<Swiper | null>>;
  onContentSlideChange: () => void;
  activeMenu: number;
  testPlanStatus: TestPlanStatus[];
};

const MembershipTestPlanQueue = ({
  setSwiperContent,
  onContentSlideChange,
  activeMenu,
  testPlanStatus,
}: MembershipTestPlanQueueProps) => (
  <S.SliderContainer data-testid={MEMBERSHIP_TEST_PLAN.QUEUE}>
    <ReactSwiper
      onSwiper={setSwiperContent}
      onSlidePrevTransitionEnd={onContentSlideChange}
      onSlideNextTransitionEnd={onContentSlideChange}
      pagination={false}
      className="mySwiper"
      navigation={false}
      spaceBetween={20}
      slidesPerView={1}
      initialSlide={activeMenu}
    >
      {testPlanStatus?.map((status) => (
        <SwiperSlide key={status.id}>
          {status.items?.length ? (
            status?.items?.map((test: any) => (
              <TestPlanCard key={test.id} {...test} />
            ))
          ) : (
            <MembershipTestPlanEmpty isClaimTabActive={activeMenu === 1} />
          )}
        </SwiperSlide>
      ))}
    </ReactSwiper>
  </S.SliderContainer>
);

export default MembershipTestPlanQueue;
