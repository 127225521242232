import React from 'react';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import Swiper from 'swiper/types/swiper-class';
import { MEMBERSHIP_TEST_PLAN } from 'utils/constants/dataTest';

import * as S from '../styles';
import { TestPlanStatus } from '../types';

type MembershipTestPlanMobileNavProps = {
  setSwiperMenu: React.Dispatch<React.SetStateAction<Swiper | null>>;
  onMenuSlideChange: () => void;
  activeMenu: number;
  testPlanStatus: TestPlanStatus[];
};

const MembershipTestPlanMobileNav = ({
  setSwiperMenu,
  onMenuSlideChange,
  activeMenu,
  testPlanStatus,
}: MembershipTestPlanMobileNavProps) => (
  <S.SliderContainerMenu data-testid={MEMBERSHIP_TEST_PLAN.MOBILE_NAV}>
    <ReactSwiper
      onSwiper={setSwiperMenu}
      onSlidePrevTransitionEnd={onMenuSlideChange}
      onSlideNextTransitionEnd={onMenuSlideChange}
      centeredSlides
      slidesPerView={2}
      spaceBetween={0}
      pagination={false}
      initialSlide={activeMenu}
    >
      {testPlanStatus?.map((status) => (
        <SwiperSlide key={status.id}>
          <S.Status>
            {status.title} ({status?.items?.length})
          </S.Status>
        </SwiperSlide>
      ))}

      <S.Pagination className="swiper-pagination"></S.Pagination>
    </ReactSwiper>
  </S.SliderContainerMenu>
);

export default MembershipTestPlanMobileNav;
