import { breakpoints, Container } from '@everlywell/leaves';
import { DraggableTypes } from 'components/DraggableItem/types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import Swiper from 'swiper/types/swiper-class';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { UserSettingsContext } from 'utils/hooks/useAccountSettings/context';
import { TestPlanContext } from 'utils/hooks/useTestPlan/context';
import { TestPlanItem, TestPlanStatusIds } from 'utils/hooks/useTestPlan/types';
import { UserContext } from 'utils/hooks/useUserContext/context';

import MembershipTestPlanDesktopNav from './MembershipTestPlanDesktopNav';
import MembershipTestPlanMobileNav from './MembershipTestPlanMobileNav';
import MembershipTestPlanQueue from './MembershipTestPlanQueue';
import * as S from './styles';
import { TestPlanStatus } from './types';

export type MembershipTestPlanSectionProps = {
  uid: string;
  upcomingTests?: TestPlanItem[] | undefined;
  inProgressTests?: TestPlanItem[] | undefined;
  completedTests?: TestPlanItem[] | undefined;
  title: string;
  subHeadline: string;
  headline: string;
};

export const MembershipTestPlanSection = ({
  title,
  headline,
  uid,
}: MembershipTestPlanSectionProps): JSX.Element | null => {
  const wrapperScrollRef = useRef(null);
  const {
    userData: { loggedIn, isMember },
  } = useContext(UserContext);
  const { upcomingTests, setTestPlanActiveStatus, testPlanActiveStatus } =
    useContext(TestPlanContext);
  const { testsHistory, getTestHistory } = useContext(UserSettingsContext);
  const [swiperContent, setSwiperContent] = useState<Swiper | null>(null);
  const [swiperMenu, setSwiperMenu] = useState<Swiper | null>(null);
  const [_activeMenu, _setActiveMenu] = useState(0);
  const addTestToQueue = (item: TestPlanItem) => {
    console.log(item); // eslint-disable-line
  };

  const [{ isOver }, drop] = useDrop(() => ({
    canDrop: () => true,
    accept: DraggableTypes.INCLUDED_KIT,
    drop: (item: TestPlanItem) => addTestToQueue(item), // Function called when the item is dropped
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
  }));

  const background = isOver ? 'blue' : 'white';

  const onMenuSlideChange = () => {
    swiperMenu && swiperContent?.slideTo(swiperMenu?.activeIndex);
  };

  const onContentSlideChange = () => {
    const targetSlide = swiperContent?.activeIndex || 0;
    swiperContent && swiperMenu?.slideTo(targetSlide);
    _setActiveMenu(targetSlide);
  };

  const goToSlide = (target: number) => {
    swiperContent?.slideTo(target);
    _setActiveMenu(target);
  };

  const testPlanStatus: TestPlanStatus[] = [
    {
      id: 'upcoming',
      title: 'Upcoming tests',
      items: upcomingTests,
    },
    {
      id: 'completed',
      title: 'Claimed tests',
      items: testsHistory,
    },
  ];

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.TEST_PLAN.label,
        isMember: Boolean(isMember),
        loggedIn: isMember ? true : loggedIn,
      },
    });
    getTestHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTestPlanActiveStatus(
      _activeMenu ? TestPlanStatusIds.completed : TestPlanStatusIds.upcoming,
    );
  }, [_activeMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const active = testPlanActiveStatus === TestPlanStatusIds.completed ? 1 : 0;
    _setActiveMenu(active);
    goToSlide(active);
  }, [testPlanActiveStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const isMobile = window.innerWidth <= breakpoints.forPhoneOnly;

  return (
    <div ref={wrapperScrollRef}>
      <S.Container id={uid} data-testid={uid} ref={drop} style={{ background }}>
        <Container>
          <S.SectionTitle>{title}</S.SectionTitle>
          <S.Description>{headline}</S.Description>

          {isMobile ? (
            <MembershipTestPlanMobileNav
              setSwiperMenu={setSwiperMenu}
              onMenuSlideChange={onMenuSlideChange}
              activeMenu={_activeMenu}
              testPlanStatus={testPlanStatus}
            />
          ) : (
            <MembershipTestPlanDesktopNav
              goToSlide={goToSlide}
              activeMenu={_activeMenu}
              testPlanStatus={testPlanStatus}
            />
          )}

          <MembershipTestPlanQueue
            setSwiperContent={setSwiperContent}
            onContentSlideChange={onContentSlideChange}
            activeMenu={_activeMenu}
            testPlanStatus={testPlanStatus}
          />
        </Container>
      </S.Container>
    </div>
  );
};

export default MembershipTestPlanSection;
