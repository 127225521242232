import { typography, size, mediaQueries, colors } from '@everlywell/leaves';
import styled from 'styled-components';
// Swiper Carousel
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

export const Container = styled.section`
  ${typography.overlineText};
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${size.xl4}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl2}px 0;
  }

  ${mediaQueries.forPhoneOnly} {
    padding: ${size.xl2}px 0;
  }
`;

export const SectionTitle = styled.h2`
  ${typography.h2Text};
  text-transform: none;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h3Text};
  }
`;

export const Description = styled.div`
  ${typography.bodyTextSmall};
  width: 100%;
  text-transform: none;
  text-align: center;
  margin-top: ${size.sm}px;
`;

export const Date = styled.div`
  ${typography.bodyTextSmall};
  width: 100%;
`;

interface StatusProps {
  active?: boolean;
}

export const Status = styled.div`
  ${typography.buttonTextSmall};
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  ${(props: StatusProps) =>
    props.active
      ? `
    &:after{
      content: '';
      height: 4px;
      width: 100%;
      background: ${colors.teal5};
      position: absolute;
      left: 0;
      bottom: -10px;
    }`
      : ''}
`;

export const Pagination = styled.div`
  width: 100%;
`;

export const SliderContainerMenu = styled.div`
  overflow: hidden;
  max-width: 70%;
  margin: 0 auto;
  position: relative;

  ${mediaQueries.forPhoneOnly} {
    max-width: 100%;
  }

  &::before {
    content: '';
    width: 20px;
    height: 70px;
    display: block;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &::after {
    content: '';
    width: 20px;
    height: 70px;
    display: block;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  .swiper-container {
    height: 100%;
    padding-top: 20px;

    .swiper-wrapper {
      .swiper-slide {
        height: 35px;
        display: flex;
        align-items: center;
        min-width: 160px;
      }
    }
  }
  .swiper-pagination {
    display: none;
    ${mediaQueries.forPhoneOnly} {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 10px;

      .swiper-pagination-bullet {
        flex: 1;
        margin: 0 5%;
        border-radius: 4px;
        max-width: 20px;
        height: 4px;
        background: ${colors.green2};

        &.swiper-pagination-bullet-active {
          background: ${colors.teal4};
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  padding: ${size.xl3}px 0;
  box-sizing: border-box;

  ${mediaQueries.forPhoneOnly} {
    padding: ${size.xl2}px 0 ${size.lg}px;
    max-width: 100%;
  }

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        height: 0;
      }

      .swiper-slide-active {
        height: 100%;
      }
    }
  }
  .swiper-pagination {
    display: none;
    ${mediaQueries.forPhoneOnly} {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 10px;

      .swiper-pagination-bullet {
        flex: 1;
        margin: 0 5%;
        border-radius: 4px;
        max-width: 20px;
        height: 4px;
        background: ${colors.green2};

        &.swiper-pagination-bullet-active {
          background: ${colors.teal4};
        }
      }
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  max-width: 460px;
  margin: 0 auto;
  width: 100%;
  margin-top: ${size.xl1}px;
`;
