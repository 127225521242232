import {
  typography,
  size,
  Button,
  colors,
  H5,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const TestCard = styled.div`
  display: flex;
  border: solid 1px #d6ebdd;
  margin: 0 ${size.xl3}px;
  padding: ${size.lg}px ${size.xl1}px;
  justify-content: space-between;
  box-sizing: border-box;

  ${mediaQueries.forTabletVerticalDown} {
    margin: 0;
    flex-direction: column;
    align-items: center;
    padding: ${size.lg}px;
  }
`;

export const TestCardLeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.forTabletVerticalDown} {
    max-width: 453px;
    flex-flow: column;
    margin-top: ${size.md}px;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: ${colors.green1};
    height: 92px;
    width: 92px;
    border-radius: 50%;
    top: -17px;
    left: 3px;
  }
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  position: relative;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
`;

export const TestCardContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: ${size.lg}px;

  ${mediaQueries.forTabletVerticalDown} {
    margin-top: ${size.xl1}px;
    margin-left: 0;
  }
`;

export const TestTitle = styled(H5)`
  text-transform: none;
  width: 100%;
  margin-bottom: ${size.xs1}px;
`;

export const Description = styled.div`
  ${typography.bodyTextSmall};
  text-transform: none;
  width: 100%;

  ${mediaQueries.forTabletVerticalDown} {
    margin-bottom: ${size.lg}px;
  }
`;

export const TestActions = styled.div`
  max-width: 162px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: ${size.md}px;

  ${mediaQueries.forTabletVerticalDown} {
    align-items: center;
    max-width: 453px;
    width: 100%;
    margin-left: 0;
  }
`;

export const TestButton = styled(Button)`
  width: 100%;
  margin-bottom: ${size.md}px;
  text-transform: capitalize;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RemoveButton = styled(Button)`
  border: none;
  padding: 0;
  color: ${colors.green5};

  &:hover {
    background: transparent;
    color: ${colors.green5};
  }
`;
