import {
  typography,
  mediaQueries,
  size,
  Button,
  H5,
  colors,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const TestPlanEmptyWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.green1};
  margin-top: ${size.lg}px;

  ${mediaQueries.forPhoneOnly} {
    padding: 0 ${size.xl1}px;
    text-align: center;
  }
`;

export const ImageWrapper = styled.figure`
  margin: ${size.xl1}px 0 ${size.md}px;
  width: 100%;
  max-width: 150px;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const AddTestsTitleWrapper = styled(H5)`
  text-transform: none;
  margin-bottom: ${size.md}px;
`;

export const AddTestsSubtitleWrapper = styled.div`
  ${typography.bodyText}
  text-transform: none;
  margin-bottom: ${size.lg}px;
`;

export const AddTestsWrapper = styled.div`
  margin-bottom: ${size.lg}px;
`;

export const AddTests = styled(Button)`
  min-width: 195px;
`;
